import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import browser from './browser'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: app,
    browser: browser,
  },
  strict: process.env.NODE_ENV !== 'production',
})

if (module.hot) {
  module.hot.accept(['./app', './browser', ], () => {
    store.hotUpdate({
      modules: {
        app: require('./app'),
        browser: require('./browser'),
      },
    })
  })
}

export default store
