
const utils = {
  encode (data) {
    return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&")
  },
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  getFirstName(name) {
    let a = name.split(' ')
    return a[0]
  },
  scrollToElementById(id) {
    id = id.replace('#', '')
    let $el = document.getElementById(id)
    if ($el) {
      let pos = $el.offsetTop
      window.gsap.to(window, {
        scrollTo: pos - 30,
        duration: .9
      })
    }
  },
  findHashInElementArray(arr) {
    let hash = ''
    let count = 0
    while (count < arr.length && !hash) {
      if (arr[count].hash) {
        hash = arr[count].hash
      }
      count ++
    }
    return hash
  },
  routerLinkAsEl(to) {
    return {
      path: [
        {
          hash: to
        }
      ]
    }
  }
}

export default utils
