import utils from "@/utils/utils";

export default {
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    routerClick (path) {
      utils.scrollToElementById(path)
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
