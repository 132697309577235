<template lang="pug" src="./html/Privacy.pug"></template>

<script>
import MixinBrowserController from "@/mixins/MixinBrowserController";

export default {
  mixins: [ MixinBrowserController ]
}
</script>

<style lang="sass" src="./style/Minimal.sass" scoped></style>
