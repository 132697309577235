import { render, staticRenderFns } from "./App.pug?vue&type=template&id=d8c5c4f0&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./style/_reset.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./style/anims.sass?vue&type=style&index=1&lang=sass&"
import style2 from "./style/App.sass?vue&type=style&index=2&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports