<template lang="pug" src="./html/Home.pug"></template>

<script>
import utils from "@/utils/utils";
import autosize from "autosize";
import MixinBrowserController from "@/mixins/MixinBrowserController";
import server from "@/services/server";

export default {
  data () {
    return {
      emailValid: true,
      formValid: false,
      stateFormResponse: false,
      form: {
        name: "",
        email: "",
        message: "",
      }
    }
  },
  mixins: [ MixinBrowserController ],
  mounted () {
    autosize(this.$refs.messageTextarea)
  },
  computed: {
    firstName () {
      return utils.getFirstName(this.form.name)
    }
  },
  methods: {
    checkFields () {
      this.emailValid = !this.form.email || utils.validateEmail(this.form.email)
      this.formValid = this.form.name && this.form.email && this.emailValid
    },
    sendMail() {
      server.submitContactFrom(this.form)
      this.stateFormResponse = true
      this.routerClick('contact-us')
    },
    sendAnother() {
      this.stateFormResponse = false
    }
  }
}
</script>

<style lang="sass" src="./style/Home.sass" scoped></style>
