import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

// STATE
const state = {
  ip: null,
  userAgent: navigator.userAgent,
  language: navigator.language,
  deviceRes: window.screen.width + '×' + window.screen.height
}

// EXPORT
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
