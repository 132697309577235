<template lang="pug" src="./App.pug" />

<script>
import CookiePolicy from '@/components/CookiePolicy'
import MixinBrowserController from '@/mixins/MixinBrowserController'
import server from "@/services/server";
import { mapMutations } from 'vuex'

const VERSION = 'v_1.1.7'

export default {
  mixins: [ MixinBrowserController ],
  components: {
    CookiePolicy
  },
  mounted () {
    console.log('VERSION', VERSION)
    server.getMeta((data) => {
      this.metaDataReceived(data)
    })
  },
  methods: {
    ...mapMutations('browser', ['metaDataReceived'])
  }
}
</script>

<style lang="scss" src="./style/_reset.scss"></style>
<style lang="sass" src="./style/anims.sass"></style>
<style lang="sass" src="./style/App.sass"></style>
