import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Privacy from '@/views/Privacy.vue'
import Legal from '@/views/Legal.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
