import axios from "axios";
import utils from "@/utils/utils";
import store from '@/store'

const BASE = '/.netlify/functions/'

class Server {
  getMeta (cb) {
    axios.get(BASE + 'meta').then(res => {
      if (res.data) cb(res.data)
    })
  }
  submitContactFrom (form) {
    let browser = store.state.browser
    axios.post(
      "/",
      utils.encode({
        "form-name": "contact-us",
        'device-ip': browser.ip,
        'device-user-agent': browser.userAgent,
        'device-language': browser.language,
        'device-resolution': browser.deviceRes,
        ...form
      }),
      {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    );
  }
}

const service = new Server()
export default service
