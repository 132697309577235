<template lang="pug" src="./html/CookiePolicy.pug"></template>

<script>
const ACCEPT_COOKIES = 'ACCEPT_COOKIES'
const SHOW_COOKIE_DELAY = 800

export default {
  data () {
    return {
      active: false,
      accepted: this.$cookies.isKey(ACCEPT_COOKIES)
    }
  },
  mounted() {
    setTimeout(() => {
      this.active = !this.accepted
    }, SHOW_COOKIE_DELAY)
  },
  methods: {
    acceptCookies () {
      this.$cookies.set(ACCEPT_COOKIES,true, '30d')
      this.active = false
    }
  }
}
</script>

<style lang="sass" src="./style/CookiePolicy.sass" scoped></style>
